import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { ProductService } from '../services/product.service'
import { ConfigurationService } from '../services/configuration.service'
import { CookieService } from 'ngx-cookie-service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { VendorService } from '../services/vendor.service'
import { WishlistService } from '../services/wishlist.service';
import { WishedProductService } from '../services/wished-product.service';
import { WishedProduct } from '../models/wished-product.model';
import { BlogsService } from '../services/blogs.service'
const data = require("../../data/store-data.json");
import templateString from './product-listing.component.html';
import styleString from './product-listing.component.scss';
import { DomSanitizer } from '@angular/platform-browser'
import { SessionService } from '../services/session.service'
import { SubfoldingLinkService } from '../services/subfolding-link.service';
import { CookiesResetService } from '../services/cookies-reset.service';

@Component({
  selector: 'app-product-listing',
  template: templateString,
  styles: [ styleString ],
  providers:  [ ProductService, ConfigurationService, VendorService, WishlistService, WishedProductService, BlogsService, SessionService, SubfoldingLinkService  ]
})

export class ProductListingComponent implements OnInit {

  @Input() vendorId: any;
  @Input() vendorMicroSite: any;

  products= [];
  isSelectedStore;
  pagination:any;
  totalCount;
  p: number = 1;
  perPage: number = 24;
  sortBy=this.window['data']['default_products_filter'];
  selectedSort=this.window['data']['default_products_filter'];
  filterSelected=false;
  priceBetween = null;
  filterArray=[{}, ""];
  taxon_id;
  breadcrum;
  breadcrumList = [];
  currency;
  search;
  screenWidth;
  vendor;
  htmlStruct;
  body;
  toggle = {};
  taxon_details;
  permaLink;
  selectedCurrency = "USD";
  vendorDesignerText = null;
  messageText="";
  expanded:boolean;
  collapseRef:boolean;
  collapseSort:boolean;
  vendorLogo = null;
  selectedStoreCode;
  storeCodeMapping = data["storeCodeMappingofProductDetailWithGraphCms"]
  vendorVisibility;
  throttle = 300;
  scrollDistance = 1;
  start_page:number;
  devId:string;
  showLoadMore: boolean = true;
  storeCongfPerPage: number = 24;
  accessToken = '';
  textOnImage = '';
  bannerPosition = '';
  urlImage = '';
  listingsType;

  constructor(private window: Window,
    private spinnerService: Ng4LoadingSpinnerService,
    private productService: ProductService,
    private configurationService: ConfigurationService,
    private platformLocation: PlatformLocation,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private vendorService: VendorService,
    private wishlistService: WishlistService,
    private wishedProductService: WishedProductService,
    private blogsService: BlogsService,
    private sanitizer: DomSanitizer,
    public sessionService: SessionService,
    public subfoldingLinkService: SubfoldingLinkService,
    public cookiesResetService: CookiesResetService
  ) {
    this.isSelectedStore = this.productService.isSelectedStore();
    this.htmlStruct = document.getElementsByTagName('html')[0];
    this.body = document.getElementsByTagName('body')[0];
    this.currency = this.cookiesResetService.get('curencySymbol')
    this.accessToken = this.cookiesResetService.get("access_token");
    if(this.window.screen){
      this.screenWidth = this.window.screen.width;
    }
    this.selectedStoreCode = this.storeCodeMapping[this.cookiesResetService.get('storeCode')]

    // this.platformLocation.onPopState((event) => {
    //   //BACK PRESSED
    //   this.window.location.href = this.window.location.href;
    // });

    if(this.window["data"])
      this.vendorVisibility = this.window["data"]["storeItemVisibility"]["vendorVisibility"]
      this.perPage = this.window["data"]["perPage"];
      this.storeCongfPerPage = this.window["data"]["perPage"];
  }

  ngOnInit() {
    console.log('listing store_id:'+this.isSelectedStore);
    this.start_page = +this.route.snapshot.queryParams['page'];
    if(this.cookiesResetService.getItem('selected_sort')) this.selectedSort = this.cookiesResetService.getItem('selected_sort');
    this.filterSelected = false;
    if(!this.window.location.href.includes('catalogsearch/result')) this.cookiesResetService.removeItem('search_filters');
    this.resetFilters();
    this.bootstrap();
  }

  onScrollDown () {
    console.log('scrolled down!!');
    this.p++;
    this.getCategory();
  }

  resetFilters(){
    this.p = 1;
    this.perPage = this.storeCongfPerPage;
    this.sortBy = this.window['data']['default_products_filter'];
    // this.selectedSort = this.window['data']['default_products_filter'];
    this.filterArray = [{},""];
  }

  bootstrap(){
    this.selectedCurrency = this.cookiesResetService.get("curency")
    this.getParams();
    this.getCategory();
    this.getVendorData();

    if (this.permaLink){
      this.configurationService.getPermalinkBreadcrum(this.permaLink).subscribe((data) => {
        if(data["data"]){
          // this.breadcrum = data;
          let taxon = data["data"]['attributes'];
          this.taxon_details = data["data"];
          this.breadcrum = taxon['breadcrums'];
          this.textOnImage = taxon['banner_text'];
          this.bannerPosition = taxon['banner_position'];
          this.urlImage = taxon['banner_image']['url'];

          let slug = "";
          let slugs;
          this.breadcrum.forEach(item => {
            let obj = {};
            if(item["slug"] != "categories"){
              obj["name"] = item["name"];
              slugs = item['permalink'].split('/');
              slugs = slugs[slugs.length - 1];
              slug = slug + "/" + slugs;
              obj["slug"] = slug;
              this.breadcrumList.push(obj);
            }
          });
          this.breadcrumList.push({ name: taxon["name"], slug: "" });
          this.messageText = data["data"]['attributes']['description'];
          console.log(taxon);
          if (this.taxon_details['id']){
            this.body.setAttribute("data-taxon-id", this.taxon_details['id']);
            this.listingsType = taxon['slug'] + '-products';
          }
        }
      });
    }

    this.expanded = false;
    this.collapseRef = false;
    this.collapseSort= false;
  }

  parseParam(param){
    try{
      return JSON.parse(param)
    }catch(e){
      return null
    }
  }

  getParams(){
    this.route.paramMap.subscribe((params) => {
      if(params.get('vendor_id')) this.vendorId = params.get('vendor_id');

      let subPath = params.get('sub');
      if(params.get('id') && subPath === "products"){
        this.vendorId = params.get('id');
        this.vendorMicroSite = true;
      } else this.vendorMicroSite = false;
    });
    this.route.queryParams.subscribe(params => {
      this.filterArray = this.parseParam(params['filters']) || this.filterArray;

      if(params['filters']){
        let query_filters = { property_filters: JSON.parse(params['filters']), price_between: JSON.parse(params['price']) };
        this.cookiesResetService.setItem('search_filters', JSON.stringify(query_filters));
      }

      this.p = this.parseParam(params['page']) || this.p;
      // if(params['visit']) {
      //   this.start_page = this.p;
      // }
      this.perPage = this.parseParam(params['pageSize']) || this.perPage;
      this.sortBy = this.parseParam(params['sort']) || this.sortBy;
      this.priceBetween = this.parseParam(params['price']) || this.priceBetween;
      // this.devId = params["devId"];
      // console.log("this.devId")
      // console.log(this.devId)
   });
    this.route.params.subscribe(params => {
      let catParams = window.location.pathname.split('/');
      if(params['slug']) catParams.splice(1,1);
      if(params['lang']) catParams.splice(1,1);

      if(params['term']) this.search = params['term'];
      else if(this.vendorMicroSite != true){
        if (params['id'] || this.cookiesResetService.get('curencySymbol')){
          if (catParams[8]){
            this.permaLink = "categories" + "/" + catParams[1] + "/" + catParams[2] + "/" + catParams[3] + "/" + catParams[4]+ "/" + catParams[5]+ "/" + catParams[6]+ "/" + catParams[7]+ "/" + catParams[8];
          }else if (catParams[7]){
            this.permaLink = "categories" + "/" + catParams[1] + "/" + catParams[2] + "/" + catParams[3] + "/" + catParams[4]+ "/" + catParams[5]+ "/" + catParams[6]+ "/" + catParams[7];
          }else if (catParams[6]){
            this.permaLink = "categories" + "/" + catParams[1] + "/" + catParams[2] + "/" + catParams[3] + "/" + catParams[4]+ "/" + catParams[5]+ "/" + catParams[6];
          }else if (catParams[5]){
            this.permaLink = "categories" + "/" + catParams[1] + "/" + catParams[2] + "/" + catParams[3] + "/" + catParams[4]+ "/" + catParams[5];
          }else if (catParams[4]){
            this.permaLink = "categories" + "/" + catParams[1] + "/" + catParams[2] + "/" + catParams[3] + "/" + catParams[4];
          }else if (catParams[3]){
            this.permaLink = "categories" + "/" + catParams[1] + "/" + catParams[2] + "/" + catParams[3];
          }else if (catParams[2]){
            this.permaLink = "categories" + "/" + catParams[1] + "/" + catParams[2];
          }else if(catParams[1]){
            this.permaLink = "categories" + "/" + catParams[1];
          }else if(params['term']){
            this.search = params['term'];
            console.log("SEARCH TERM IS: ", params['term'])
          }
          // if (params['sub5']){
          //   this.permaLink = "categories" + "/" +params['category'] + "/" +  params['id'] + "/" +params['sub'] + "/" +params['sub1']+ "/" +params['sub2']+ "/" +params['sub3']+ "/" +params['sub4']+ "/" +params['sub5']
          // }else if (params['sub4']){
          //   this.permaLink = "categories" + "/" +params['category'] + "/" +  params['id'] + "/" +params['sub'] + "/" +params['sub1']+ "/" +params['sub2']+ "/" +params['sub3']+ "/" +params['sub4']
          // }else if (params['sub3']){
          //   this.permaLink = "categories" + "/" +params['category'] + "/" +  params['id'] + "/" +params['sub'] + "/" +params['sub1']+ "/" +params['sub2']+ "/" +params['sub3']
          // }else if (params['sub2']){
          //   this.permaLink = "categories" + "/" +params['category'] + "/" +  params['id'] + "/" +params['sub'] + "/" +params['sub1']+ "/" +params['sub2']
          // }else if (params['sub1']){
          //   this.permaLink = "categories" + "/" +params['category'] + "/" +  params['id'] + "/" +params['sub'] + "/" +params['sub1']
          // }else if (params['sub']){
          //   this.permaLink = "categories" + "/" +params['category'] + "/" +  params['id'] + "/" +params['sub']
          // }else if (params['category']){
          //   this.permaLink = "categories" + "/" +params['category'] + "/" +  params['id']
          // }else if(params['id']){
          //   this.permaLink = "categories" +  "/" +  params['id']
          // }else if(params['term']){
          //   this.search = params['term'];
          //   console.log("SEARCH TERM IS: ", params['term'])
          // }
        }else{
          this.router.navigate(["/"]);
        }
      }
    });
  }

  expand() {
    this.expanded = true;
  }

  collapse() {
    this.expanded = false;
  }

  openRef() {
    this.collapseRef = true;
    this.body.classList.add('scroll-hidden');
    this.htmlStruct.classList.add("scroll-hidden-html");
  }

  closeRef() {
    this.collapseRef = false;
    this.body.classList.remove('scroll-hidden');
    this.htmlStruct.classList.remove("scroll-hidden-html");
  }

  openSort() {
    this.collapseSort = true;
    this.body.classList.add('scroll-hidden');
    this.htmlStruct.classList.add("scroll-hidden-html");
  }

  closeSort() {
    this.collapseSort = false;
    this.body.classList.remove('scroll-hidden');
    this.htmlStruct.classList.remove("scroll-hidden-html");
  }

  getVendorData(){
    if(this.vendorId){
      this.vendorService.get_vendor(this.vendorId).subscribe(
        (vendor) => {
          this.vendor = vendor["data"];
          if(this.vendor.attributes.image["data"]){
            this.vendorLogo = this.vendor.attributes.image.data.attributes.styles[2].url;
          }
          this.vendorDesignerText = this.sanitizer.bypassSecurityTrustHtml(this.vendor.attributes.designerText);

          this.body.setAttribute("data-vendor-id", this.vendor.id);
          this.listingsType = this.vendor.attributes.slug + '-products';
        });
    }
  }

  setSorting(event){
    this.closeSort();
    this.sortBy = event;
    this.cookiesResetService.setItem('selected_sort', this.sortBy);
    this.selectedSort = this.sortBy;
    this.p = 1;
    this.start_page = 1;
    this.products = [];
    this.getCategory();
  }

  showAll(){
    this.closeRef();
    // this.perPage = this.totalCount
    // this.getCategory();
  }

  scrollX = 0;
  scrolY = 0;
  scrollable = false;
  ngAfterViewChecked(){
    if(this.scrollable){
      this.window.scrollTo(this.scrollX, this.scrolY);
      this.scrollable = false;
    }
  }

  getCategory(){
    // this.products = []
    this.spinnerService.show();
    this.scrollX = this.window.pageXOffset;
    this.scrolY = this.window.pageYOffset;
    this.showLoadMore = false;
    if(this.selectedSort) this.sortBy = this.selectedSort;

    var search_filters = JSON.parse(this.cookiesResetService.getItem('search_filters'));
    if(!this.filterSelected && search_filters && Object.keys(search_filters["property_filters"][0]).length > 0){
      this.priceBetween = search_filters["price_between"];
      this.filterArray = search_filters["property_filters"];
    }

    this.productService.getCategory(this.p, this.perPage, this.taxon_id, this.sortBy, this.filterArray, this.priceBetween, this.vendorId, this.permaLink, this.search).subscribe((data) => {
      
      var queryParams = {};

      if(this.filterArray && JSON.stringify(this.filterArray[0]) != JSON.stringify({}) ){
        var queryFilters = JSON.stringify(this.filterArray);
        queryParams['filters'] = queryFilters
      }

      if(this.sortBy != this.window['data']['default_products_filter']){
        var sortFilter = JSON.stringify(this.sortBy);
        queryParams['sort'] = sortFilter
      }

      if(this.priceBetween){
        var priceFilter = JSON.stringify(this.priceBetween);
        queryParams['price'] = priceFilter
      }

      if(this.p != 1){
        var queryPage = JSON.stringify(this.p);
        queryParams['page'] = queryPage
      }

      if(!this.search){
        const queryParamsSnap = this.route.snapshot.queryParams;
        const difference = {};
        
        if (JSON.stringify(queryParamsSnap) !== JSON.stringify(queryParams)){
          for (const key in queryParamsSnap) {
            if (queryParamsSnap[key] !== queryParams[key])
              difference[key] = queryParamsSnap[key];
          }
        queryParams = { ...queryParams, ...difference };
      }
      this.router.navigate([], { relativeTo: this.route, queryParams: queryParams });
    }

      this.pagination = data["links"];
      this.totalCount = data["meta"]['total_count'];
      for (var i = 0; i < data["data"].length; i++) {
        data["data"][i]["pageNumber"] = this.p;
      }
      this.products = this.products.concat(data["data"]);
      this.setWishedProduct(this.products);
      this.scrollable = true;
      this.showLoadMore = true;
      this.spinnerService.hide();
    });
  }

  // Scrolling Task
  // ngAfterViewChecked() {
  //   console.log("this.devId ngAfterView")
  //   console.log(this.devId)
  //   if(this.devId) {
  //     console.log(document.getElementById(this.devId));
  //     if(document.getElementById(this.devId)) {
  //       console.log("Got the Element")
  //       document.getElementById('kyle-duncan-000199').scrollIntoView();
  //       this.devId = '';
  //     }
  //   }
  // }


  setWishedProduct(products){
    if(this.accessToken){
      this.wishlistService.findUserList().subscribe(
        (wishlist) => {
          let wishedItems = wishlist["data"].attributes.wished_products.data
          wishedItems.forEach( wishedProdut => {
            products.forEach( product => {
              if(wishedProdut.attributes.prodcut_id == product.id){
                product.wished = true
                product.wishedProductId = wishedProdut.id
              }
            })
          });
        });
    }else{
      var wishedProduts = JSON.parse(this.cookiesResetService.getItem('guest_wish_list'))
      products.forEach( product => {
        if(wishedProduts && wishedProduts.includes(product.id)){
          product.wished = true
        }else{
          product.wished = false
        }
      })
    }
  }

  onSelectingFilters(filters){
    this.filterSelected = true;
    this.priceBetween = filters["price_between"];
    this.filterArray = filters["property_filters"];
    this.p = 1;
    this.start_page = 1;
    this.products = [];
    this.getCategory()
  }


  addProductToWishList(product, status){
    if(this.accessToken){
      if(status == "remove"){
        this.wishedProductService.delete(product.wishedProductId).subscribe( obj => {
          product.wished = false
          product.wishedProductId = null
        });
      }else{
        let wishedProdutData = new WishedProduct(product.id);
        this.wishedProductService.save(wishedProdutData).subscribe((data: any)  =>{
          product.wished = true
          product.wishedProductId = data.wished_product_id
        });
      }
    }else{
      if(this.cookiesResetService.getItem('guest_wish_list')){
        var wishedProduts = JSON.parse(this.cookiesResetService.getItem('guest_wish_list'))
        if(status == "remove"){
          product.wished = false
          var index = wishedProduts.indexOf(product.id);
          wishedProduts.splice(index, 1);
        }else{
          product.wished = true
          wishedProduts.push(product.id);
        }
        this.cookiesResetService.setItem('guest_wish_list', JSON.stringify(wishedProduts));
      }else{
        product.wished = true
        this.cookiesResetService.setItem('guest_wish_list', JSON.stringify([product.id]));
      }
    }
    // if(status != "remove"){
    //   if(this.window["gtag"]){
    //     this.window["gtag"]('event', 'add_to_wishlist', {
    //       currency: this.selectedCurrency,
    //       items: [{
    //         id: product.attributes.slug,
    //         name: product.attributes.name,
    //         brand: product.attributes.vendor_name,
    //         price: product.attributes.price
    //       }],
    //       value: product.attributes.price
    //     });
    //   }
    // }
  }

  getBreadCrumbs(){
    let list;
    if(this.vendor){
      let attrs = this.vendor.attributes;
      var landing_page_url = "";
      if(attrs.microsite == "true")
        landing_page_url =  "/vendor/" + attrs.landingPageUrl;
      list = [
        { name: attrs.name, slug: landing_page_url},
        { name: "Products", slug: ""}
      ]
    }
    return list;
  }

  // Reload all the previous products
  onReloadCollection() {
    if(this.start_page > 1) {
      this.start_page--;
      this.showPreviousProducts();
    }
  }


  // Request Previous Products and append it with current products
  showPreviousProducts() {
    this.spinnerService.show();
    this.productService.getCategory(this.start_page, this.perPage, this.taxon_id, this.sortBy, this.filterArray,
      this.priceBetween, this.vendorId, this.permaLink, this.search).subscribe((data) => {
        for (var i = 0; i < data["data"].length; i++) {
          data["data"][i]["pageNumber"] = this.start_page;
        }
        this.products = data["data"].concat(this.products);
        this.setWishedProduct(this.products);
        this.spinnerService.hide();
      });
  }


  // View the product on click
  onClickProduct(product, productNumber:number, event) {

    console.log('CTRL pressed during click:', event.ctrlKey);

    let slug = product.attributes.slug;

    var queryParams = {};

      if(this.filterArray && JSON.stringify(this.filterArray[0]) != JSON.stringify({}) ){
        var queryFilters = JSON.stringify(this.filterArray);
        queryParams['filters'] = queryFilters
      }

      if(this.sortBy != this.window['data']['default_products_filter']){
        var sortFilter = JSON.stringify(this.sortBy);
        queryParams['sort'] = sortFilter
      }

      if(this.priceBetween){
        var priceFilter = JSON.stringify(this.priceBetween);
        queryParams['price'] = priceFilter
      }

      if(product.pageNumber != 1){
        var queryPage = JSON.stringify(product.pageNumber);
        queryParams['page'] = queryPage
      }

    //   if(slug) {
    //     queryParams['devId'] = slug;
    //   }

      // queryParams['visit'] = "true";

      this.router.navigate([], { relativeTo: this.route, queryParams: queryParams});

      if(event.ctrlKey) {
        window.open("/"+slug );
      }
      else {
        window.location.href = "/"+slug;
      }

  }

  loadProduct(path) {
    this.router.navigate([path])
  }

}
