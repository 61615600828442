import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { BaseService } from "./base.service"
import { CookieService } from 'ngx-cookie-service';
import { CookiesResetService } from './cookies-reset.service';


@Injectable()
export class CartService extends BaseService {
  protected http: HttpClient;
  itemAdded: EventEmitter<any> = new EventEmitter();
  options;
  store_id;
  currency;

  constructor(http: HttpClient, private cookieService: CookieService,
              public cookiesResetService: CookiesResetService) {
    super("cart", http);
    this.http = http;

    this.currency = this.cookiesResetService.get('curency');
    this.store_id = this.cookiesResetService.get('store');

    this.options = {
      headers: new HttpHeaders(
          {
            'Content-type': 'application/json',
            'X-Store-Id': this.store_id,
            'X-Currency': this.currency,
            'X-Spree-Order-Token': this.cookiesResetService.get('order_token'),
            'X-Spree-Cart-Token': JSON.parse(this.cookiesResetService.getSessionItem('cart_token')) || 'null'
          }
      )
    };
  }

  resetHeader(){
    this.currency = this.cookiesResetService.get('curency');
    this.store_id = this.cookiesResetService.get('store');
    let orderToken = window['data']['singlePageStore']? JSON.parse(this.cookiesResetService.getSessionItem('order_token')) : this.cookiesResetService.get('order_token')

    this.options = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        'X-Store-Id': this.store_id,
        'X-Currency': this.currency,
        'X-Spree-Order-Token': orderToken,
        'X-Spree-Cart-Token': JSON.parse(this.cookiesResetService.getSessionItem('cart_token')) || 'null'
      })
    };
  }

  setCartTokenHeader(){
    const cart_token = JSON.parse(this.cookiesResetService.getSessionItem('cart_token')) || 'null';
    this.options.headers = this.options.headers.set('X-Spree-Cart-Token', cart_token);
  }

  tsCardTopup(topup_options){
    this.resetHeader();
    return this.http.put(`${this.baseUrl}/ts_card_topup` , topup_options, this.options)
  }

  tsCardActivation(card_options){
    this.resetHeader();
    return this.http.put(`${this.baseUrl}/ts_card_activation` , card_options, this.options)
  }

  tsTransactionEmails(body){
    this.resetHeader();
    body["order_token"] = window['data']['singlePageStore']? JSON.parse(this.cookiesResetService.getSessionItem('order_token')) : this.cookiesResetService.get('order_token')
    return this.http.put(`${this.baseUrl}/ts_transaction_emails` , body, this.options)
  }

  createPaymentCart(currency, body){
  let store_id = this.cookiesResetService.get('store');
    const headers = new HttpHeaders({
      'Content-type': 'application/json',
      'X-Store-Id': store_id,
      'X-Currency': currency
    });
    const options = {
      headers: headers
    };
    return this.http.post(`${this.baseUrl}/create_payment_cart`, body, options)
  }

  createIframeCart(body){
    let order_token = this.cookiesResetService.get('order_token');
    if (order_token) body.order_token = order_token;
    return this.http.post(`${this.baseUrl}/create_iframe_cart`, body, this.options);
  }

  createCart(){
    let headers = new HttpHeaders(
        {
          'Content-type': 'application/json',
          'X-Store-Id': this.store_id,
          'X-Currency': this.currency
        }
    )
    let options = {
        headers: headers
    };

    if (this.cookiesResetService.get('access_token')){
      const access_token = this.cookiesResetService.get('access_token')
      let params = new HttpParams();
      params = params.set("q", access_token)
      params = params.set("access_token", access_token)
      options['params'] =params
    }
    return this.http.post(this.baseUrl,'',options)
  }

  refreshCart(){
    let headers = new HttpHeaders(
      {
      'Content-Type': 'application/js',
      'X-Store-Id': this.store_id,
      'X-Currency': this.currency,
      'X-Spree-Order-Token': this.cookiesResetService.get('order_token')
      }
    )
    let options = { headers: headers};
    if (this.cookiesResetService.get('access_token')){
      const access_token = this.cookiesResetService.get('access_token')
      let params = new HttpParams();
      params = params.set("q", access_token)
      params = params.set("access_token", access_token)
      options['params'] =params
    }
    // return this.http.get("http://localhost:3000/storefront/cart/data", options)
  }

  getCart(customParseFields?:string, resetState?){
    const access_token = this.cookiesResetService.get('access_token');
    this.options = {
      headers: new HttpHeaders(
        {
          'Content-type': 'application/json',
          'X-Store-Id': this.store_id,
          'X-Currency': this.currency,
          'X-Spree-Order-Token': this.cookiesResetService.get('order_token') || 'null'
        }
      )
    };

    var parseFields = "?&fields[cart]=token,price_values,state,all_gift_card,line_items,valid_for_promotional_code,coupon_code,shipments,shipping_address,number,total,currency,email,store_id&selected=true"
    var reset_state = '';

    if(customParseFields) {
      parseFields = customParseFields;
    }
    if(resetState){
        reset_state = "&reset_state=true"
    }

    if (access_token && access_token != "null")
      return this.http.get(this.baseUrl + parseFields + "&access_token=" + access_token + reset_state, this.options);

    return this.http.get(this.baseUrl+ parseFields + reset_state, this.options);
  }

  addItem(productForm, customOptions){
    let currency = this.cookiesResetService.get('curency');
    let store_id = window['data']['storeId'];

    const options = {
      headers: new HttpHeaders(
          {
            'Content-type': 'application/json',
            'X-Store-Id': store_id,
            'X-Currency': currency,
            'X-Spree-Order-Token': this.cookiesResetService.get('order_token')
          }
      )
    };
    let cusOptions = {
        "glo_api": true,
        "vendor_id": productForm['vendor_id'].value,
        "customization_options": customOptions,
        "user_currency": currency,
        "store_id": store_id,
        receipient_email: productForm['receipient_email'].value, receipient_first_name: productForm['receipient_first_name'].value,
        receipient_last_name: productForm['receipient_last_name'].value, receipient_phone_number: productForm['receipient_phone_number'].value, message: productForm['message'].value,
        sender_name: productForm['sender_name'].value
    }
    if(productForm['delivery_mode_with_type'].value) cusOptions["delivery_mode"] = productForm['delivery_mode_with_type'].value;

    let body = {};
    if(productForm['dailyStock'].value){
      let variants = productForm['dailyStockVariants'].value;
      variants.forEach(variant => {
        variant["options"] = cusOptions
      })
      body = JSON.stringify({
        "variants": variants,
        "buy_now_button": productForm['buy_now_button'].value,
        "options": cusOptions,
        "cart_changed": true
      });
    }else{
      body = JSON.stringify({
        "variants": [{
          "id": JSON.stringify(productForm['variant'].value),
          "quantity": JSON.stringify(productForm['quantity'].value),
          "options": cusOptions,
        }],
        "buy_now_button": productForm['buy_now_button'].value,
        "cart_changed": true
      });
    }
    return this.http.post(this.baseUrl + "/add_item", body, options)
  }

  setQuantity(line_item_id, quantity, cart_changed = true){
    this.options = {
      headers: new HttpHeaders(
          {
            'Content-type': 'application/json',
            'X-Store-Id': this.store_id,
            'X-Currency': this.currency,
            'X-Spree-Order-Token': this.cookiesResetService.get('order_token'),
            'X-Spree-Cart-Token': JSON.parse(this.cookiesResetService.getSessionItem('cart_token')) || 'null'
          }
      )
    };

    const body = JSON.stringify({
      "quantity": quantity,
      "line_item_id": line_item_id,
      "cart_changed": cart_changed
    });
    return this.http.patch(this.baseUrl + "/set_quantity", body, this.options)
  }

  applyCouponCode(couponCode, order_updated_at){
    const body = JSON.stringify({
      "coupon_code":couponCode,
      "apply": true,
      "order": { "updated_at": order_updated_at }
    });
    let headers = new HttpHeaders(
        {
          'Content-type': 'application/json',
          'X-Store-Id': this.store_id,
          'X-Currency': this.currency,
          "X-Spree-Order-Token": this.cookiesResetService.get('order_token'),
          'X-Spree-Cart-Token': JSON.parse(this.cookiesResetService.getSessionItem('cart_token')) || 'null',
          "COUPON_CODE": couponCode
        }
    )
    let options = {
      headers: headers
    };

    return this.http.patch(this.baseUrl + "/apply_coupon_code", body, options)
  }

  removeCouponCode(couponCode, order_updated_at){
    const body = JSON.stringify({
      "apply": false
    });
    let headers = new HttpHeaders(
        {
          'Content-type': 'application/json',
          'X-Store-Id': this.store_id,
          'X-Currency': this.currency,
          "X-Spree-Order-Token": this.cookiesResetService.get('order_token'),
          'X-Spree-Cart-Token': JSON.parse(this.cookiesResetService.getSessionItem('cart_token')) || 'null',
          "COUPON_CODE": couponCode
        }
    )
    let options = {
      headers: headers
    };
    let paramArray = '?order[updated_at]=' + order_updated_at;
    return this.http.delete(this.baseUrl + "/remove_coupon_code/" + couponCode + "" + paramArray, options)
  }

  notifyItemAdded() {
    console.log("sending event");
    this.itemAdded.emit();
  }

  getItemAddedEmitter() {
    return this.itemAdded;
  }

  deleteItem(line_item_id, order_updated_at, cart_changed = true){
    let paramArray;
    paramArray = `?order_token=${this.cookiesResetService.get('order_token')}`;
    paramArray += `&cart_token=${(JSON.parse(this.cookiesResetService.getSessionItem('cart_token')) || 'null')}`;
    paramArray += `&cart_changed?${cart_changed}`
    paramArray += `&order[updated_at]=${order_updated_at}`;
    return this.http.delete(this.baseUrl + "/remove_line_item/" + line_item_id + "" + paramArray)
  }
}
