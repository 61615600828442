import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { RatingModule, StarRatingComponent } from 'ng-starrating';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng5SliderModule } from 'ng5-slider';
import { MatDialogModule } from "@angular/material/dialog";
import { CreditCardDirectivesModule } from 'angular-cc-library';
// import { RecaptchaModule } from 'angular-google-recaptcha';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxStripeModule } from 'ngx-stripe';

import { HomeCarouselComponent } from './home-carousel/home-carousel.component';
import { LoginSignupComponent } from './login-signup/login-signup.component';
import { GuestCheckoutComponent } from './session/guest-checkout/guest-checkout.component';
import { LoginComponent } from './session/login/login.component';
import { SignupComponent } from './session/signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import {AddressComponent} from './../checkout/address/address.component'

import { FeaturedClientComponent } from './featured-client/featured-client.component';
import { FooterCheckoutComponent } from './footer-checkout/footer-checkout.component';
import { RelatedProductsComponent } from './related-products/related-products.component';
import { ReviewsComponent } from './reviews/reviews.component';

import { CustomPipe } from './../custom-pipe.module';

import { RefreshService } from './../services/refresh.service';
import { SessionService } from './..//services/session.service'
// import { SeoService } from './services/seo.service'

import { AuthGuard } from './..//guards/auth.guard';
import { CustomerGuard } from './..//guards/customer.guard';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CartService } from './..//services/cart.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RemoveSpacePipe } from './../product-listing/page-sidebar/remove-space.pipe';
// import { NewNewsletterComponent } from './new-newsletter/new-newsletter.component';
// import { DatePickerDirective } from './datepicker.directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccumulativePricePipe } from '../pipes/accumulative-price.pipe';
import { TruncateTextPipe } from '../pipes/truncate-text.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { NoShipLocationComponent } from '../product-detail/no-ship-location/no-ship-location.component';
import { OutOfStockComponent } from '../product-detail/out-of-stock/out-of-stock.component';
import { MaxTransactionExceededComponent } from '../product-detail/max-transaction-exceeded/max-transaction-exceeded.component';
import { BlogComponent } from '../blog/blog.component';
import { BlogPostComponent } from '../blog/blog-post/blog-post.component';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { DailyStockProductComponent } from '../product-detail/daily-stock-product/daily-stock-product.component';
import { ProductListingComponent } from '../product-listing/product-listing.component';
import { PageSidebarComponent } from '../product-listing/page-sidebar/page-sidebar.component';
import {HeaderInterceptor} from "../services/header-interceptor";
import { BuyNowGuestCheckoutComponent } from './session/buy-now-session/buy-now-guest-checkout/buy-now-guest-checkout.component';
import { BuyNowLoginCartComponent } from './session/buy-now-session/buy-now-login-cart/buy-now-login-cart.component';
import { BuyNowLoginComponent } from '../product-detail/buy-now-login/buy-now-login.component';
import { BuyNowLoginV3Component } from '../product-detail/buy-now-login-v3/buy-now-login-v3.component';
import { ConfirmBuyNowWithCartComponent } from '../product-detail/confirm-buy-now-with-cart/confirm-buy-now-with-cart.component';
import { AlertPopupComponent } from './alert-popup/alert-popup.component';
import { ProductInfoComponent } from '../product-detail/product-info/product-info.component';
import { DecimalPointPipe } from '../pipes/decimal-point.pipe';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BuyNowGuestV3CheckoutComponent } from './session/buy-now-v3-session/buy-now-v3-guest-checkout/buy-now-v3-guest-checkout.component';
import { IframeComponent } from '../product-detail/iframe/iframe.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import {NgHcaptchaModule} from "ng-hcaptcha";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    SafeHtmlPipe,
    HomeCarouselComponent,
    LoginSignupComponent,
    GuestCheckoutComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NewsletterComponent,
    BreadcrumbsComponent,
    AddressComponent,
    FeaturedClientComponent,
    FooterCheckoutComponent,
    RelatedProductsComponent,
    ReviewsComponent,
    RemoveSpacePipe,
    // NewNewsletterComponent,
    NoShipLocationComponent,
    OutOfStockComponent,
    MaxTransactionExceededComponent,
    BlogComponent,
    BlogPostComponent,
    ProductListingComponent,
    ProductDetailComponent,
    DailyStockProductComponent,
    PageSidebarComponent,
    BuyNowGuestCheckoutComponent,
    BuyNowLoginCartComponent,
    BuyNowLoginComponent,
    BuyNowLoginV3Component,
    ConfirmBuyNowWithCartComponent,
    AlertPopupComponent,
    ProductInfoComponent,
    BuyNowGuestV3CheckoutComponent,
    IframeComponent
  ],
  imports: [
    RouterModule.forChild([]),
    MatDialogModule,
    OverlayModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    Ng4LoadingSpinnerModule.forRoot(),
    RatingModule,
    NgxPaginationModule,
    Ng5SliderModule,
    CreditCardDirectivesModule,
    NgMultiSelectDropDownModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxStripeModule.forRoot(''),
    InfiniteScrollModule,
    CustomPipe,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CommonModule,
    CarouselModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgHcaptchaModule.forRoot()
],
  exports: [
    HomeCarouselComponent,
    LoginSignupComponent,
    GuestCheckoutComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NewsletterComponent,
    BreadcrumbsComponent,
    AddressComponent,
    FeaturedClientComponent,
    FooterCheckoutComponent,
    RelatedProductsComponent,
    ReviewsComponent,
    RemoveSpacePipe,
    // NewNewsletterComponent,
    AccumulativePricePipe,
    TruncateTextPipe,
    SafeHtmlPipe,
    RatingModule,
    NgxPaginationModule,
    Ng5SliderModule,
    BlogComponent,
    BlogPostComponent,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ProductListingComponent,
    ProductDetailComponent,
    DailyStockProductComponent,
    PageSidebarComponent,
    BuyNowGuestCheckoutComponent,
    BuyNowLoginCartComponent,
    BuyNowLoginComponent,
    BuyNowLoginV3Component,
    ConfirmBuyNowWithCartComponent,
    DecimalPointPipe,
    BuyNowGuestV3CheckoutComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true}, ProductDetailComponent, DailyStockProductComponent, IframeComponent],
  entryComponents: [
    MaxTransactionExceededComponent,
    BuyNowLoginComponent,
    BuyNowLoginV3Component,
    ConfirmBuyNowWithCartComponent,
    AlertPopupComponent
  ]
})
export class SharedModule { }
